<template>
  <div id="speakerssection" class="px-12">
    <!-- <v-container> -->
    <v-row class="text-center white--text">
      <!-- <v-col cols="12">
        <v-img
          :src="require('../assets/logo.svg')"
          class="my-3"
          contain
          height="200"
        />
      </v-col>-->

      <v-col class="mb-2" cols="12">
        <div class="py-12"></div>
        <h3 class="mb-4 display-1">Contenuti speciali</h3>
        <p class="font-weight-light title">Ascolta le testimonianze</p>
      </v-col>
    </v-row>

    <v-row class="mb-12">
      <v-col
        class="col-md-3 col-12 align-start d-flex flex-column"
        v-for="(speaker, index) in loadedSpeakers"
        :key="index"
      >
        <v-hover v-slot:default="{ hover }">
          <v-card
            class="mx-auto speakerCard flex d-flex flex-column align-start"
            dark
            style=""
            :elevation="hover ? 6 : 3"
            @click="goToSpeaker(speaker)"
          >
            <v-img
              :src="`${speaker.downloadImgUrl}`"
              class=""
              height="200px"
              style="max-height: 200px"
            >
              <div
                class="d-flex justify-end pt-2 pr-2"
                v-if="speaker.areas_of_expertise"
              >
                <v-chip color="primary" label x-small>{{
                  speaker.areas_of_expertise[0]
                }}</v-chip>
              </div>
            </v-img>

            <v-card-title class
              >{{ speaker.first_name }} {{ speaker.last_name }}</v-card-title
            >

            <v-card-subtitle class="caption">
              <span class="primary--text">{{ speaker.organization }}</span>
              <br />
              <span class="font-italic">{{ speaker.role }}</span>
            </v-card-subtitle>
          </v-card>
        </v-hover>
      </v-col>

      <v-col class="col-md-3 col-12">
        <div class="pl-7">
          <!-- <p class="headline">E tanti altri</p> -->
          <v-btn class="primary seeall" to="/speakers">Vedi tutti</v-btn>
        </div>
      </v-col>
    </v-row>

    <div class="py-6"></div>
    <!-- </v-container> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      speakers: [],
    };
  },
  computed: {
    loadedSpeakers() {
      return this.$store.getters.loadedSpeakers;
    },
  },
  methods: {
    goToSpeaker(speaker) {
      this.$router.push(`/speakers/${speaker.slug}`);
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style scoped>
.grey-background {
  background-color: #f4f4f4 !important;
}
.speakerCard {
  border: 1px solid rgb(255, 255, 255, 0.3) !important;
  background-color: black !important;
}
.speakerCard:hover {
  border: 1px solid rgb(255, 255, 255, 0.4) !important;
  background-color: #212121 !important;
}
.seeall {
  margin-top: 130px;
}
</style>